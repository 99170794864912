const I18NextHttpBackend = require("i18next-http-backend/cjs");

module.exports = {
  i18n: {
    locales: ["fr", "es"],
    defaultLocale: "fr",
    localeDetection: false,
    domains:
      process.env.PLASMIC_ENV === "production"
        ? [
            {
              domain: "benebono.fr",
              defaultLocale: "fr",
            },
            {
              domain: "benebono.es",
              defaultLocale: "es",
            },
          ]
        : [
            {
              domain: "next-staging.benebono.es",
              defaultLocale: "es",
            },
            {
              domain: "next-staging.benebono.fr",
              defaultLocale: "fr",
            },
            //domains value must follow format { domain: 'example.fr', defaultLocale: 'fr', locales: ['fr'] }.
          ],
  },
  backend: {
    allowMultiLoading: false,
    loadPath: (lng) => {
      const topLevelDomain = window.location.hostname.split(".").pop();
      const lang = topLevelDomain === "es" ? "es-ES" : "fr-FR";
      return [
        process.env.NEXT_PUBLIC_TRANSLATION_BUCKET_URL,
        `${lang}.json`,
      ].join("/");
    },
  },
  use: [I18NextHttpBackend],
};
